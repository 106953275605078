import { render, staticRenderFns } from "./home.vue?vue&type=template&id=d7efeb9c&lang=pug"
var script = {}


/* normalize component */
import normalizer from "!../../node_modules/.pnpm/vue-loader@15.11.1_css-loader@6.11.0_webpack@5.94.0__lodash@4.17.21_pug@3.0.3_vue-template-co_lb2esybnb2zfriczjjfzoaj6k4/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports