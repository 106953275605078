<!-- eslint-disable vue/multi-word-component-names -->
<template lang="pug">
div(class="container")
  div(class="card")
    header(class="card-header")
      p(class="card-header-title") Webhooks
    div(class="card-content")
      div(class="content")
        nx-table(
          urlFetch="mailgun/list"
          ref="nxTable"
          :backendPagination="false"
          :backendSorting="false"
          :selectedColumns="[\
            'type',\
            'url',\
          ]"
        )

          template(#buttonHeader)
            b-button(
              label="Attach"
              icon-left="plus"
              type="is-primary"
              @click="attach()"
            )
            b-button(
              label="Detach"
              icon-left="delete"
              type="is-danger"
              @click="detach()"
            )

    footer(class="card-footer")

</template>

<script>
// @ts-expect-error no types
import nxTable from '@nx-t/vue-components/vue2/nxTableOld';

const axios = require('redaxios').default;

export default {
  components: {
    nxTable,
  },
  methods: {
    attach() {
      axios
        .get('/mailgun/attach.listener')
        .then((response) => {
          if (response.data && response.data.success) {
            return;
          }
          if (response.data && response.data.errorMessage) {
            // eslint-disable-next-line no-alert
            alert(response.data.errorMessage);
          } else {
            // eslint-disable-next-line no-alert
            alert(response.data);
          }
        })
        .catch((error) => {
          // eslint-disable-next-line no-alert
          alert(error.toString());
        })
        .then(() => {
          this.$refs.nxTable.fetchData();
        });
    },
    detach() {
      axios
        .get('/mailgun/attach.listener?deleteAllTypes=true')
        .then((response) => {
          if (response.data && response.data.success) {
            return;
          }
          if (response.data && response.data.errorMessage) {
            // eslint-disable-next-line no-alert
            alert(response.data.errorMessage);
          } else {
            // eslint-disable-next-line no-alert
            alert(response.data);
          }
        })
        .catch((error) => {
          if (error.response && error.response.data) {
            // eslint-disable-next-line no-alert
            alert(error.response.data.toString());
          } else {
            // eslint-disable-next-line no-alert
            alert(error.toString());
          }
        })
        .then(() => {
          this.$refs.nxTable.fetchData();
        });
    },
  },
};

</script>
