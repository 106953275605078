import Vue from 'vue';
import Router from 'vue-router';
import home from './vue/home';
import mailList from './vue/mail/list';
import webhookList from './vue/webhook/list';

Vue.use(Router);

export default new Router({
  mode: 'history',
  routes: [
    { path: '/', component: home },
    { path: '/mail/', component: mailList },
    { path: '/webhook/', component: webhookList },
  ],
});
