<template lang="pug">
div#page
  div#header
    b-navbar(fixed-top shadow spaced)
      template(#brand)
        b-navbar-item(tag="router-link" :to="{ path: '/' }")
          img(
            src="/bezahlde.gif"
            alt=""
            srcset=""
          )
      template(#start)
        b-navbar-item(tag="router-link" :to="{ path: '/' }") Home
        b-navbar-item(tag="router-link" :to="{ path: '/mail' }") Mails
        b-navbar-item(tag="router-link" :to="{ path: '/webhook' }") Webhooks

      template(#end)
        b-navbar-item(tag="div")
          a(:href="`/login?redirectUrl=${currentRoute}`")
            b-button(
              label="Login"
              icon-left="login"
            )
  router-view(class="is-fluid")

</template>

<script>
import Vue from 'vue';

export default Vue.extend({
  data() {
    return {
      currentRoute: encodeURIComponent(window.location.toString()),
    };
  },
  watch: {
    $route() {
      this.currentRoute = encodeURIComponent(window.location.toString());
    },
  },
});
</script>
