<!-- eslint-disable vue/multi-word-component-names -->
<template lang="pug">
div(class="container")
  div(class="card")
    header(class="card-header")
      p(class="card-header-title") Mails
    div(class="card-content")
      div(class="content")
        nx-table(
          urlFetch="mail/list"
          urlDelete="mail/delete"
          ref="nxTable"
          :selectedColumns="[\
            'stack',\
            'createdAt',\
            'to',\
            'from',\
            'subject',\
          ]"
          :checkable="true"
        )
          template(#column-createdAt="row") {{ formattedDate(row.createdAt) }}
          template(#column-to="row") {{ row.to.join('; ') }}
          template(#column-cc="row") {{ row.cc.join('; ') }}
          template(#column-bcc="row") {{ row.bcc.join('; ') }}
          template(#column)
            b-table-column(v-slot="props")
              b-tooltip(label="Feedbacks" multilined)
                b-button(
                  icon-left="forum"
                  type="is-ghost is-medium"
                  class="iconOnly"
                  @click="info(props.row.feedbacks, 'Feedbacks')"
                )
              b-tooltip(label="callbackParameter" multilined)
                b-button(
                  icon-left="phone"
                  type="is-ghost is-medium"
                  class="iconOnly"
                  @click="info(props.row.callbackParameter, 'callbackParameter')"
                )
              b-tooltip(label="createResponse" multilined)
                b-button(
                  icon-left="plus-circle-outline"
                  type="is-ghost is-medium"
                  class="iconOnly"
                  @click="info(props.row.createResponse, 'createResponse')"
                )
              b-tooltip(label="Text" multilined)
                b-button(
                  icon-left="text"
                  type="is-ghost is-medium"
                  class="iconOnly"
                  @click="displayText(props.row.text)"
                )
              b-tooltip(label="HTML" multilined)
                b-button(
                  icon-left="file-image-outline"
                  type="is-ghost is-medium"
                  class="iconOnly"
                  @click="displayHtml(props.row.html)"
                )

    footer(class="card-footer")
  b-modal(
    :active.sync="showForm"
    has-modal-card
    :full-screen="htmlData ? true : false"
    @close="close"
  )
    div(class="modal-card" style="width: auto")
      header(class="modal-card-head")
        p(class="modal-card-title") {{ modalTitle }}
      section(class="modal-card-body")
        b-field
          pre(v-if="preData") {{ preData }}
          iframe(
            v-if="htmlData"
            :srcdoc="htmlData"
            frameborder="0"
            height="100%"
            width="100%"
          )
      footer(class="modal-card-foot")
</template>

<script>
import moment from 'moment';
// @ts-expect-error no types
import nxTable from '@nx-t/vue-components/vue2/nxTableOld';

export default {
  components: {
    nxTable,
  },
  data() {
    return {
      showForm: false,
      preData: null,
      htmlData: null,
      modalTitle: '',
    };
  },
  methods: {
    info(data, title) {
      this.showForm = true;
      this.modalTitle = title;
      this.preData = data;
    },
    displayText(data) {
      this.showForm = true;
      this.modalTitle = 'Text';
      this.preData = data;
    },
    displayHtml(data) {
      this.showForm = true;
      this.modalTitle = 'HTML';
      this.htmlData = data;
    },
    close() {
      this.showForm = false;
      this.preData = null;
      this.htmlData = null;
    },
    formattedDate(date) {
      return moment(date).format('DD.MM.YYYY HH:mm:ss');
    },
  },
};

</script>

<style scoped>
  .iconOnly {
    height: 1em;
  }
</style>
